import { ChangeEvent, FC, useCallback, useState } from "react";
import { toast } from "react-toastify";
import useEditMemo from "../hooks/useEditMemo";
import Popup from "../Popup";
import { ActionPopupProps } from "../types";

const MemoEditPopup: FC<ActionPopupProps & { id: string; memo?: string }> = ({
  onClose,
  id,
  memo,
  onSuccess,
}) => {
  const [editMemo, setEditMemo] = useState<string>(memo ?? "");

  const { mutate: editMemoApi } = useEditMemo({
    onSuccess: () => {
      onClose();
      toast.success("메모 수정 성공");

      if (onSuccess instanceof Function) {
        onSuccess();
      }
    },
  });

  const changeEditMemo = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setEditMemo(e.target.value);
  }, []);

  const editMemoFunc = () => {
    editMemoApi({ id, memo: editMemo });
  };

  return (
    <Popup
      buttonTitle="저장"
      onButtonClick={editMemoFunc}
      title={`메모 수정(${id})`}
      onClose={onClose}
    >
      <div className="field">
        <label className="label">메모</label>
        <div className="control">
          <input
            className="input"
            value={editMemo}
            onChange={changeEditMemo}
            type="text"
          />
        </div>
      </div>
    </Popup>
  );
};

export default MemoEditPopup;

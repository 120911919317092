import { useQuery } from "react-query";
import httpClient from "../client";
import { AccountDB } from "../types";

const useAccounts = (params?: any) => {
  return useQuery<AccountDB[]>(
    ["accounts", params],
    async () => {
      const res = await httpClient.get("/accounts", { params: params });
      return res.data;
    },
    {
      keepPreviousData: true,
    }
  );
};

export default useAccounts;

import { FC, KeyboardEvent, useCallback } from "react";

interface Props {
  onSearch: (value: string | undefined) => void;
  placeholder?: string;
}

const SearchInput: FC<Props> = ({ onSearch, placeholder }) => {
  const keyPressHandler = useCallback(
    (e: KeyboardEvent<HTMLInputElement>) => {
      if (e.key === "Enter") {
        const inputEle = e.target as HTMLInputElement;
        onSearch(inputEle.value.length === 0 ? undefined : inputEle.value);
      }
    },
    [onSearch]
  );
  return (
    <input
      className="input"
      placeholder={placeholder}
      onKeyDown={keyPressHandler}
    />
  );
};

export default SearchInput;

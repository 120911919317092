import { useMutation, UseMutationOption } from "react-query";
import client from "../client";

const useCreateCategory = (option?: UseMutationOption) => {
  return useMutation(async () => {
    await client.post("/category");
  }, option);
};

export default useCreateCategory;

import { useQuery } from "react-query";
import httpClient from "../client";

const useMonthMoney = (timestamp: number) => {
  return useQuery(["/payment/month", timestamp], async () => {
    const res = await httpClient.get(`/payment/month`, {
      params: {
        timestamp,
      },
    });
    return res.data;
  });
};

export default useMonthMoney;

import { useMutation, UseMutationOption } from "react-query";
import httpClient from "../client";

const useEditPayment = (option?: UseMutationOption) => {
  return useMutation(
    async ({ paymentId, bool }: { paymentId: number; bool: boolean }) => {
      await httpClient.put(`/payment/${paymentId}`, {
        bool,
      });
    },
    option
  );
};

export default useEditPayment;

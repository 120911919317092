import { useMutation, UseMutationOption } from "react-query";
import httpClient from "../client";

const useEditMemo = (option?: UseMutationOption) => {
  return useMutation(async ({ id, memo }: { id: string; memo: string }) => {
    await httpClient.put(`/account/memo/${id}`, {
      memo,
    });
  }, option);
};

export default useEditMemo;

import {
  ChangeEvent,
  FC,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { toast } from "react-toastify";
import styled from "styled-components";
import SearchInput from "../components/SearchInput";
import Table from "../components/Table";
import useAccounts from "../hooks/useAccounts";
import useEditServicePoint from "../hooks/useEditServicePoint";
import Popup from "../Popup";
import {
  FlexDiv,
  PageHeaderFilterWrapper,
  SelectedTableRow,
  TableWrapper,
} from "../styles";
import { AccountDB, ActionPopupProps, ServiceDB } from "../types";

const accountHeaders = ["유저ID", "누적 충전 금액"];

const FixedHeightTableWrapper = styled.div`
  height: 400px;
`;

const MarginForm = styled(FlexDiv)`
  margin-top: 20px;
`;

const ServicePointEditPopup: FC<ServiceDB & ActionPopupProps> = ({
  onClose,
  onSuccess,
  text,
  point,
  id,
}) => {
  const [keyword, setKeyword] = useState<string | undefined>();
  const [userDetail, setUserDetail] = useState<AccountDB | null>(null);
  const [editPoint, setEditPoint] = useState<number>(0);

  const { data: accounts = [] } = useAccounts({ keyword });

  const { mutate: editServicePointApi } = useEditServicePoint({
    onSuccess: () => {
      toast.success("서비스 포인트를 수정했습니다");
      onClose();

      if (onSuccess instanceof Function) {
        onSuccess();
      }
    },
  });

  const disabled = userDetail === null;

  const changeEditPoint = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setEditPoint(Number(e.target.value));
  }, []);

  const changeServicePoint = () => {
    if (userDetail === null) {
      toast.error("유저를 선택해 주세요");
      return;
    }

    editServicePointApi({
      point: editPoint,
      serviceId: id,
      userId: userDetail.id,
    });
  };

  const currentServicePoint = useMemo(() => {
    if (userDetail === null) return 0;
    const currentPoint = userDetail.servicePoint[id] ?? point;

    return currentPoint;
  }, [id, point, userDetail]);

  const renderCallback = (item: AccountDB) => {
    return (
      <SelectedTableRow
        key={item.id}
        onClick={() => {
          setUserDetail(item);
        }}
        className={item.id === userDetail?.id ? "active" : ""}
      >
        <td>{item.id}</td>
        <td>{item.totalMoney}</td>
      </SelectedTableRow>
    );
  };

  useEffect(() => {
    setEditPoint(0);
  }, [userDetail]);

  return (
    <Popup
      title={`"${text}" 의 포인트 수정`}
      onClose={onClose}
      buttonTitle="저장"
      onButtonClick={changeServicePoint}
    >
      <>
        <PageHeaderFilterWrapper>
          <SearchInput onSearch={setKeyword} placeholder="유저ID" />
        </PageHeaderFilterWrapper>
        <FixedHeightTableWrapper>
          <TableWrapper>
            <Table
              headers={accountHeaders}
              data={accounts}
              renderCallback={renderCallback}
            />
          </TableWrapper>
        </FixedHeightTableWrapper>
        <MarginForm>
          <div className="field">
            <label className="label">원본 금액 (1개당)</label>
            <div className="control">
              <input className="input" type="text" value={point} disabled />
            </div>
          </div>
          <div className="field">
            <label className="label">현재 유저 금액 (1개당)</label>
            <div className="control">
              <input
                className="input"
                type="text"
                value={currentServicePoint}
                disabled
              />
            </div>
          </div>
          <div className="field">
            <label className="label">수정 금액 (1개당)</label>
            <div className="control">
              <input
                className="input"
                type="number"
                disabled={disabled}
                value={editPoint.toString()}
                onChange={changeEditPoint}
              />
            </div>
          </div>
        </MarginForm>
      </>
    </Popup>
  );
};

export default ServicePointEditPopup;

import { FC, useCallback, useMemo, useState } from "react";
import { toast } from "react-toastify";
import SearchInput from "../components/SearchInput";
import Table from "../components/Table";
import confirm from "../confirm";
import useCategory from "../hooks/useCategory";
import useCreateService from "../hooks/useCreateService";
import useDeleteService from "../hooks/useDeleteService";
import useEditCategoryService from "../hooks/useEditCategoryService";
import useServices from "../hooks/useServices";
import ServiceEditPopup from "../popup/ServiceEditPopup";
import {
  AddButton,
  PageContainer,
  SelectedTableRow,
  TableWrapper,
} from "../styles";
import { ServiceDB } from "../types";

const headers = [
  "ID",
  "서비스 이름",
  "가격",
  "서비스 타입",
  "등록 카테고리",
  "서비스 삭제",
];

const ServicePage: FC = () => {
  const [keyword, setKeyword] = useState<string>("");
  const [serviceDetail, setServiceDetail] = useState<ServiceDB | null>(null);

  const { data: services = [], refetch: refetchServices } = useServices();
  const { data: categorys = [], refetch: refetchCategorys } = useCategory();

  const { mutate: editCategoryServiceApi } = useEditCategoryService({
    onSuccess: () => {
      toast.success("등록카테고리 변경 성공");
      refetchCategorys();
    },
  });

  const { mutate: createServiceApi } = useCreateService({
    onSuccess: () => {
      toast.success("서비스 생성 성공");
      refetchServices();
    },
  });

  const { mutate: deleteServiceApi } = useDeleteService({
    onSuccess: () => {
      toast.success("서비스 삭제 성공");
      refetchServices();
    },
  });

  const keywordHandler = useCallback((str: string | undefined) => {
    setKeyword(str ?? "");
  }, []);

  const createService = useCallback(() => {
    createServiceApi(1);
  }, [createServiceApi]);

  const deleteService = useCallback(
    async (detail: ServiceDB) => {
      const bool = await confirm({
        title: "서비스 삭제",
        content: `"${detail.text}", (${detail.id})가 삭제 됩니다`,
      });
      if (!bool) return;
      deleteServiceApi(detail.id);
    },
    [deleteServiceApi]
  );

  const dropdownOptions = useMemo(() => {
    return categorys.map((item) => ({
      text: item.text,
      value: item.id,
    }));
  }, [categorys]);

  const closeServiceEditPopup = useCallback(() => {
    setServiceDetail(null);
  }, []);

  const renderCallback = (item: ServiceDB) => {
    const targetCategory = categorys.find((category) =>
      category.services.includes(item.id)
    );

    return (
      <SelectedTableRow
        key={item.id}
        onClick={() => {
          setServiceDetail(item);
        }}
      >
        <td>{item.id}</td>
        <td>{item.text}</td>
        <td>{item.point}</td>
        <td>
          {item.serviceType} - {item.service}
        </td>
        <td>
          <EditCategoryIdInput
            datas={dropdownOptions}
            value={targetCategory?.id}
            onChange={(categoryId) => {
              if (categoryId === -1) return;
              editCategoryServiceApi({ categoryId, serviceId: item.id });
            }}
          />
        </td>
        <td>
          <button
            className="button is-outlined is-danger"
            onClick={(e) => {
              e.stopPropagation();
              deleteService(item);
            }}
          >
            서비스 삭제
          </button>
        </td>
      </SelectedTableRow>
    );
  };

  const filterKeyword = useMemo(() => {
    return services.filter((item) => item.text.includes(keyword));
  }, [services, keyword]);

  return (
    <PageContainer>
      {serviceDetail !== null && (
        <ServiceEditPopup
          {...serviceDetail}
          onClose={closeServiceEditPopup}
          onSuccess={refetchServices}
        />
      )}
      <AddButton className="button is-info is-outlined" onClick={createService}>
        서비스 추가
      </AddButton>
      <SearchInput placeholder="서비스 이름" onSearch={keywordHandler} />

      <TableWrapper>
        <Table
          headers={headers}
          data={filterKeyword}
          renderCallback={renderCallback}
        />
      </TableWrapper>
    </PageContainer>
  );
};

const EditCategoryIdInput = ({
  datas,
  value,
  onChange,
}: {
  datas: { text: string; value: number }[];
  value?: number;
  onChange: (value: number) => void;
}) => {
  return (
    <div className="select is-success">
      <select
        value={value}
        onClick={(e) => e.stopPropagation()}
        onChange={(e) => {
          onChange(Number(e.target.value));
        }}
      >
        <option value={-1}>없음</option>
        {datas.map((data) => (
          <option
            key={data.value}
            value={data.value}
            onClick={() => onChange(data.value)}
          >
            {data.text}
          </option>
        ))}
      </select>
    </div>
  );
};

export default ServicePage;

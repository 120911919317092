import { ReactNode } from "react";
import styled from "styled-components";
import { TableWrapper } from "../styles";

const Container = styled.div`
  border: 1px solid rgb(219, 219, 219) !important;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: auto;
`;
const StyledTableBody = styled.tbody`
  td,
  th {
    padding: 15px 10px;
    font-size: 13px;
    word-break: break-word;
    white-space: pre-wrap;
  }

  td + td {
    border-left: 1px solid rgb(219, 219, 219);
  }
  td {
    border-bottom: 1px solid rgb(219, 219, 219) !important ;
  }
`;
const StyledTable = styled.table`
  table-layout: fixed;
  flex: 1;
  overflow: auto;
  border-spacing: 0 !important;
  border-collapse: initial !important;
`;
const StyledHeader = styled.div`
  display: flex;
  width: 100%;
  border-bottom: 1px solid rgb(219, 219, 219);
`;
const StyledHeaderItem = styled.div`
  flex: 1;
  padding: 15px 10px;
  font-size: 15px;
  white-space: pre-wrap;
  font-weight: bold;
  border: 1px solid transparent;

  & + & {
    border-left: 1px solid rgb(219, 219, 219) !important;
  }
`;
export const InnerTableWrapper = styled(TableWrapper)`
  margin: 0 !important;
  overflow: auto;
  &::-webkit-scrollbar {
    display: none;
  }
`;

interface Props<T> {
  headers: string[];
  renderCallback: (data: T) => ReactNode;
  data: T[];
}

const Table = <T,>({ headers, renderCallback, data }: Props<T>) => {
  return (
    <Container>
      <StyledHeader>
        {headers.map((str, i) => (
          <StyledHeaderItem key={i}>{str}</StyledHeaderItem>
        ))}
      </StyledHeader>
      <InnerTableWrapper>
        <StyledTable className="table">
          <StyledTableBody>
            {data.map((row) => renderCallback(row))}
          </StyledTableBody>
        </StyledTable>
      </InnerTableWrapper>
    </Container>
  );
};

export default Table;

import { useQuery, UseQueryOption } from "react-query";
import httpClient from "../client";
import { DeleteAccountDB } from "../types";

const useDeleteAccounts = (option?: UseQueryOption) => {
  return useQuery<DeleteAccountDB[]>(
    "deleteAccount",
    async () => {
      const response = await httpClient.get("/un-registers");
      return response.data;
    },
    option
  );
};

export default useDeleteAccounts;

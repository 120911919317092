import { useQuery } from "react-query";
import httpClient from "../client";
import { PointChangeDB } from "../types";

const useEditPoints = (param: any) => {
  return useQuery<PointChangeDB[]>(["point-edits", param], async () => {
    const response = await httpClient.get("/point-edits", { params: param });
    return response.data;
  });
};

export default useEditPoints;

import { useMutation, UseMutationOption } from "react-query";
import httpClient from "../client";

const useDeleteService = (option?: UseMutationOption) => {
  return useMutation(async (id: number) => {
    await httpClient.delete(`/service/${id}`);
  }, option);
};

export default useDeleteService;

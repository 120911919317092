import { ChangeEvent, FC, useCallback, useState } from "react";
import { toast } from "react-toastify";
import styled from "styled-components";
import Pagination from "../components/Pagination";
import SearchInput from "../components/SearchInput";
import Table from "../components/Table";
import confirm from "../confirm";
import useEditPayment from "../hooks/useEditPayment";
import usePayments from "../hooks/usePayments";
import useToggleState from "../hooks/useToggleState";
import FeePopup from "../popup/FeePopup";
import PaymentCaseViewPopup from "../popup/PaymentCaseViewPopup";
import {
  AddButton,
  PageContainer,
  PageHeaderFilterWrapper,
  TableWrapper,
} from "../styles";
import { PaymentDB } from "../types";
import { PaymentFeeType, PaymentStatus } from "../types/enum";
import {
  dateFormat,
  krwFormat,
  paymentStatusFormat,
  renderFeeType,
} from "../utils";

const headers = [
  "입금 ID",
  "유저 ID",
  "입금자명",
  "입금 금액",
  "입금 신청 시간",
  "입금 상태",
  "세금",
];

const EditPaymentButton = styled.button`
  padding: 10px 20px;
  & + & {
    margin-left: 10px;
  }
`;
const LeftFilter = styled.div`
  display: flex;
  gap: 0 10px;
`;

const PaymentPage: FC = () => {
  const [keyword, setKeyword] = useState<string | undefined>(undefined);
  const [page, setPage] = useState<number>(1);
  const [fee, setFee] = useState<PaymentFeeType | undefined>(undefined);
  const [status, setStatus] = useState<PaymentStatus | undefined>(undefined);
  const [paymentDetail, setPaymentDetail] = useState<null | PaymentDB>(null);
  const [
    visiblePaymentPopup,
    setVisiblePaymentPopup,
    toggleVisiblePaymentPopup,
  ] = useToggleState();

  const { data: payments = [], refetch: refetchPayments } = usePayments({
    keyword,
    page,
    type: status,
    fee,
  });

  const { mutate: editPaymentApi } = useEditPayment({
    onSuccess: (_, data) => {
      const fix = data.bool ? "수락" : "거절";

      toast.success(`입금이 ${fix} 되었습니다`);
      refetchPayments();
    },
  });

  const changeFee = useCallback((e: ChangeEvent<HTMLSelectElement>) => {
    if (e.target.value === "") {
      setFee(undefined);
      return;
    }
    setFee(Number(e.target.value));
  }, []);

  const changeStatus = useCallback((e: ChangeEvent<HTMLSelectElement>) => {
    if (e.target.value === "") {
      setStatus(undefined);
      return;
    }
    setStatus(Number(e.target.value));
  }, []);

  const closePaymentPopup = useCallback(() => {
    setPaymentDetail(null);
  }, []);

  const editPayment = useCallback(
    async ({ paymentId, bool }: { paymentId: number; bool: boolean }) => {
      const fix = bool ? "수락" : "거절";
      const real = await confirm({
        title: `입금 ${fix}`,
        content: `입금이 ${fix} 됩니다`,
      });

      if (!real) return;

      editPaymentApi({ paymentId: paymentId, bool });
    },
    [editPaymentApi]
  );

  const renderCallback = useCallback(
    (item: PaymentDB) => {
      return (
        <tr key={item.id}>
          <td>{item.id}</td>
          <td>{item.userId}</td>
          <td>{item.name}</td>
          <td>{krwFormat(item.point, { useWon: true })}</td>
          <td>{dateFormat(item.createdAt)}</td>
          <td>
            {item.status === PaymentStatus.PENDING ? (
              <>
                <EditPaymentButton
                  className="button is-success is-outlined"
                  onClick={() =>
                    editPayment({ paymentId: item.id, bool: true })
                  }
                >
                  수락
                </EditPaymentButton>
                <EditPaymentButton
                  className="button is-danger is-outlined"
                  onClick={() =>
                    editPayment({ paymentId: item.id, bool: false })
                  }
                >
                  거절
                </EditPaymentButton>
              </>
            ) : (
              paymentStatusFormat(item.status)
            )}
          </td>
          <td>
            <a
              onClick={(e) => {
                e.preventDefault();
                setPaymentDetail(item);
              }}
            >
              {renderFeeType(item.feeType)}
            </a>
          </td>
        </tr>
      );
    },
    [editPayment]
  );

  return (
    <PageContainer>
      {visiblePaymentPopup && (
        <PaymentCaseViewPopup onClose={toggleVisiblePaymentPopup} />
      )}
      {paymentDetail !== null && (
        <FeePopup
          feeType={paymentDetail.feeType}
          feeValue={paymentDetail.feeValue}
          onClose={closePaymentPopup}
        />
      )}

      <AddButton
        onClick={toggleVisiblePaymentPopup}
        className="button is-outlined is-info "
      >
        입금 보기
      </AddButton>
      <PageHeaderFilterWrapper>
        <SearchInput placeholder="유저ID, 입금자명" onSearch={setKeyword} />
        <LeftFilter>
          <div className="select is-success">
            <select onChange={changeFee}>
              <option value={""}>전체</option>
              <option value={PaymentFeeType.NONE}>없음</option>
              <option value={PaymentFeeType.CASH}>현금영수증</option>
              <option value={PaymentFeeType.FEE}>세금계산서</option>
            </select>
          </div>
          <div className="select is-success">
            <select onChange={changeStatus}>
              <option value={""}>전체</option>
              <option value={PaymentStatus.PENDING}>대기중</option>
              <option value={PaymentStatus.SUCCESS}>수락</option>
              <option value={PaymentStatus.FAIL}>거절</option>
            </select>
          </div>
        </LeftFilter>
      </PageHeaderFilterWrapper>
      <PageHeaderFilterWrapper>
        <div />
        <Pagination page={page} onChangePage={setPage} />
      </PageHeaderFilterWrapper>
      <TableWrapper>
        <Table
          headers={headers}
          data={payments}
          renderCallback={renderCallback}
        />
      </TableWrapper>
    </PageContainer>
  );
};

export default PaymentPage;

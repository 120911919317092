import { useMutation, UseMutationOption } from "react-query";
import client from "../client";

const useEditCategoryService = (option?: UseMutationOption) => {
  return useMutation(
    async ({
      categoryId,
      serviceId,
    }: {
      categoryId: number;
      serviceId: number;
    }) => {
      await client.put(`/category/${categoryId}/${serviceId}`);
    },
    option
  );
};

export default useEditCategoryService;

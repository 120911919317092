import { useMutation, UseMutationOption } from "react-query";
import client from "../client";

const useUpdateCategory = (option?: UseMutationOption) => {
  return useMutation(
    async ({
      id,
      text,
      platform,
    }: {
      id: number;
      text: string;
      platform?: string;
    }) => {
      await client.put(`/category/${id}`, {
        text,
        platform,
      });
    },
    option
  );
};

export default useUpdateCategory;

import { ChangeEvent, FC, useCallback, useState } from "react";
import useEditPercent from "../hooks/useEditPercent";
import Popup from "../Popup";
import { toast } from "react-toastify";
import { ActionPopupProps } from "../types";

const AddPercentEditPopup: FC<
  ActionPopupProps & { id: string; addPercent: number }
> = ({ id, addPercent, onClose, onSuccess }) => {
  const [editPercent, setEditPercent] = useState<number>(addPercent);
  const { mutate: editPercentApi } = useEditPercent({
    onSuccess: () => {
      toast.success("추가 포인트 수정 성공");
      onClose();

      if (onSuccess instanceof Function) {
        onSuccess();
      }
    },
  });

  const changeEditPercent = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setEditPercent(Number(e.target.value));
  }, []);

  const editPercentFunc = () => {
    editPercentApi({ userId: id, percent: editPercent });
  };
  return (
    <Popup
      title={`추가 포인트 수정(${id})`}
      onClose={onClose}
      buttonTitle="저장"
      onButtonClick={editPercentFunc}
    >
      <div className="field">
        <label className="label">추가 포인트</label>
        <div className="control">
          <input
            className="input"
            value={editPercent.toString()}
            onChange={changeEditPercent}
            type="number"
          />
        </div>
      </div>
    </Popup>
  );
};

export default AddPercentEditPopup;

import { ChangeEvent, FC, useCallback, useState } from "react";
import "react-day-picker/dist/style.css";
import Section from "../components/Section";
import useDayMoney from "../hooks/useDayMoney";
import useMonthMoney from "../hooks/useMonthMoney";
import useTotalMoney from "../hooks/useTotalMoney";
import Popup from "../Popup";
import { FlexDiv } from "../styles";
import { ViewPopupProps } from "../types";
import { krwFormat } from "../utils";

const getDefaultDate = () => {
  const dateObj = new Date();
  const year = dateObj.getFullYear();
  const month = dateObj.getMonth();
  const date = dateObj.getDate();

  return new Date(year, month, date).getTime();
};
const getDefaultMonth = () => {
  const dateObj = new Date();
  const year = dateObj.getFullYear();
  const month = dateObj.getMonth();

  return new Date(year, month).getTime();
};

const renderDate = (stamp: number) => {
  const dateObj = new Date(stamp);
  const year = dateObj.getFullYear();
  const month = (dateObj.getMonth() + 1).toString().padStart(2, "0");
  const date = dateObj.getDate().toString().padStart(2, "0");

  return `${year}-${month}-${date}`;
};
const renderMonth = (stamp: number) => {
  const dateObj = new Date(stamp);
  const year = dateObj.getFullYear();
  const month = (dateObj.getMonth() + 1).toString().padStart(2, "0");

  return `${year}-${month}`;
};

const PaymentCaseViewPopup: FC<ViewPopupProps> = ({ onClose }) => {
  const [date, setDate] = useState<number>(getDefaultDate());
  const [month, setMonth] = useState<number>(getDefaultMonth());

  const { data: dayMoney = 0 } = useDayMoney(date);
  const { data: monthMoney = 0 } = useMonthMoney(month);
  const { data: totalMoney = 0 } = useTotalMoney();

  const changeDate = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    const dateObj = new Date(e.target.value);
    dateObj.setHours(0);

    setDate(dateObj.getTime());
  }, []);
  const changeMonth = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    const dateObj = new Date(e.target.value);
    dateObj.setHours(0);

    setMonth(new Date(e.target.value).getTime());
  }, []);

  return (
    <Popup title="입금 보기" onClose={onClose}>
      <FlexDiv>
        <Section title="총 누적">
          <div>{krwFormat(totalMoney)}</div>
        </Section>
        <Section title="날짜별">
          <>
            <input value={renderDate(date)} type="date" onChange={changeDate} />
            <div>{krwFormat(dayMoney)}</div>
          </>
        </Section>
        <Section title="월별">
          <>
            <input
              value={renderMonth(month)}
              type="month"
              onChange={changeMonth}
            />
            <div>{krwFormat(monthMoney)}</div>
          </>
        </Section>
      </FlexDiv>
    </Popup>
  );
};

export default PaymentCaseViewPopup;

import { FC } from "react";
import styled from "styled-components";

interface Props {
  page: number;
  onChangePage: (page: number) => void;
}

const Container = styled.nav`
  button,
  ul {
    order: unset !important;
  }
`;

const Pagination: FC<Props> = ({ page, onChangePage }) => {
  const disablePrevPage = page <= 1;
  const prevPage = () => {
    onChangePage(page - 1);
  };
  const nextPage = () => {
    onChangePage(page + 1);
  };
  return (
    <Container className="pagination">
      <button
        className="pagination-previous"
        disabled={disablePrevPage}
        onClick={prevPage}
      >
        {"<"}
      </button>
      <ul className="pagination-list">
        <li>
          <button className="pagination-link is-current">{page}</button>
        </li>
      </ul>
      <button className="pagination-next" onClick={nextPage}>
        {">"}
      </button>
    </Container>
  );
};

export default Pagination;

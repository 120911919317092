import { useMutation, UseMutationOption } from "react-query";
import httpClient from "../client";

const useEditServicePoint = (option?: UseMutationOption) => {
  return useMutation(
    async (value: { userId: string; point: number; serviceId: number }) => {
      const res = await httpClient.put("/service/point", {
        userId: value.userId,
        point: value.point,
        serviceId: value.serviceId,
      });

      return res.data;
    },
    option
  );
};

export default useEditServicePoint;

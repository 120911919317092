import { ChangeEvent, FC, useCallback, useState } from "react";
import { toast } from "react-toastify";
import styled from "styled-components";
import useUpdateService from "../hooks/useUpdateService";
import Popup from "../Popup";
import { FlexDiv } from "../styles";
import { ActionPopupProps, ServiceDB } from "../types";

const NoticeWrap = styled.div`
  textarea {
    font-size: 11px;
    height: 400px;
  }
`;

interface FormState {
  text: string;
  service: string;
  serviceType: string;
  notice: string;
  point: number;
  min: number;
  max: number;
}

const ServiceEditPopup: FC<ActionPopupProps & ServiceDB> = ({
  onClose,
  id,
  text,
  max,
  min,
  notice,
  point,
  service,
  serviceType,
  onSuccess,
}) => {
  const [formState, setFormState] = useState<FormState>({
    text,
    max,
    notice,
    min,
    serviceType,
    service,
    point,
  });

  const { mutate: updateServiceApi } = useUpdateService({
    onSuccess: () => {
      toast.success("서비스 수정 완료");
      onClose();

      if (onSuccess instanceof Function) {
        onSuccess();
      }
    },
  });

  const updateService = () => {
    updateServiceApi({
      id,
      ...formState,
    });
  };

  const changeFormStateStirng = useCallback(
    (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const { name, value } = e.target;
      setFormState((prev) => ({
        ...prev,
        [name]: value,
      }));
    },
    []
  );
  const changeFormStateNumber = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const { name, value } = e.target;
      setFormState((prev) => ({
        ...prev,
        [name]: Number(value),
      }));
    },
    []
  );
  return (
    <Popup
      title={`"${text}", (${id}) 수정`}
      onClose={onClose}
      buttonTitle="저장"
      onButtonClick={updateService}
    >
      <>
        <NoticeWrap className="field">
          <label className="label">설명</label>
          <div className="control">
            <textarea
              className="textarea"
              onChange={changeFormStateStirng}
              name="notice"
            >
              {formState.notice}
            </textarea>
          </div>
        </NoticeWrap>
        <div className="field">
          <label className="label">텍스트</label>
          <div className="control">
            <input
              className="input"
              type="text"
              value={formState.text}
              onChange={changeFormStateStirng}
              name="text"
            />
          </div>
        </div>
        <FlexDiv>
          <div className="field">
            <label className="label">서비스</label>
            <div className="control">
              <input
                className="input"
                type="text"
                name="service"
                value={formState.service}
                onChange={changeFormStateStirng}
              />
            </div>
          </div>
          <div className="field">
            <label className="label">서비스 타입</label>
            <div className="control">
              <input
                className="input"
                name="serviceType"
                type="text"
                value={formState.serviceType}
                onChange={changeFormStateStirng}
              />
            </div>
          </div>
        </FlexDiv>
        <FlexDiv>
          <div className="field">
            <label className="label">가격</label>
            <div className="control">
              <input
                className="input"
                name="point"
                type="number"
                value={formState.point.toString()}
                onChange={changeFormStateNumber}
              />
            </div>
          </div>
          <div className="field">
            <label className="label">최소</label>
            <div className="control">
              <input
                className="input"
                name="min"
                type="number"
                value={formState.min.toString()}
                onChange={changeFormStateNumber}
              />
            </div>
          </div>
          <div className="field">
            <label className="label">최대</label>
            <div className="control">
              <input
                className="input"
                type="number"
                name="max"
                value={formState.max.toString()}
                onChange={changeFormStateNumber}
              />
            </div>
          </div>
        </FlexDiv>
      </>
    </Popup>
  );
};

export default ServiceEditPopup;

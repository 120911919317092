import styled from "styled-components";

export const TableWrapper = styled.div`
  flex: 1;
  height: 100%;
  margin-top: 10px;
  overflow: hidden;

  table {
    width: 100%;
  }
`;

export const FlexDiv = styled.div`
  display: flex;
  gap: 0 10px;

  > * {
    flex: 1;
  }
`;

export const PageHeaderFilterWrapper = styled.div`
  display: flex;
  gap: 0 10px;
  justify-content: space-between;
  margin-bottom: 10px;

  > div {
    display: flex;
  }
`;

export const SelectedTableRow = styled.tr`
  &:hover {
    background: #f3f3f3;
    cursor: pointer;
  }

  &.active {
    background: #f3f3f3;
  }
`;

export const ListContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const PageContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  min-width: 1400px;
  flex-direction: column;
`;

export const TwoContainer = styled.div`
  display: flex;
  gap: 0 20px;
  overflow: hidden;
`;
export const DetailContainer = styled.div.attrs((props) => {
  return {
    className: "box",
  };
})`
  width: 500px;
  top: 0;
  box-sizing: border-box;
  padding: 20px;
  margin-bottom: 15px;
  border: 1px solid #eae3e3;
  border-radius: 12px;
  overflow: auto;
  table {
    text-align: center;
    width: 100%;
  }
`;
export const StyledTextArea = styled.textarea`
  width: 100%;
  font-size: 12px;
  height: 500px;
  box-sizing: border-box;
  padding: 10px;
`;
export const MarginContainer = styled.div`
  & + & {
    margin-top: 15px;
  }
`;
export const StyledInput = styled.input.attrs((_) => ({
  auatocomplete: "off",
}))`
  width: 100%;
  box-sizing: border-box;
  padding: 5px;
  font-size: 15px;
  border: 1px solid rgba(99, 114, 131, 0.3);
  border-radius: 4px;
  margin-top: 5px;
`;
export const FlexContainer = styled(MarginContainer)`
  display: flex;
  gap: 0 10px;

  > div {
    flex: 1;
  }
`;
export const StyledButton = styled.button`
  padding: 15px 0;
  width: 100%;
  box-sizing: border-box;
`;

export const AddButton = styled(StyledButton)`
  margin-bottom: 10px;
`;

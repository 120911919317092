import { FC, ReactElement } from "react";
import styled from "styled-components";

interface Props {
  children: ReactElement;
  title: string;
}

const Container = styled.section`
  padding: 15px 30px !important;

  h2 {
    margin-bottom: 10px !important;
  }
`;

const Section: FC<Props> = ({ children, title }) => {
  return (
    <Container className="section">
      <h2 className="subtitle">{title}</h2>
      {children}
    </Container>
  );
};

export default Section;

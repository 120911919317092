import { FC, useMemo, useState } from "react";
import SearchInput from "../components/SearchInput";
import Table from "../components/Table";
import useDeleteAccounts from "../hooks/useDeleteAccounts";
import {
  PageContainer,
  PageHeaderFilterWrapper,
  TableWrapper,
} from "../styles";
import { DeleteAccountDB } from "../types";
import { dateFormat, krwFormat } from "../utils";

const headers = [
  "아이디",
  "이메일",
  "포인트",
  "누적 충전 금액",
  "유저 가입 시각",
  "계정 삭제 시각",
];

const DeleteAccountPage: FC = () => {
  const [keyword, setKeyword] = useState<string | undefined>("");

  const { data: deleteAccounts = [] } = useDeleteAccounts();

  const filterKeyword = useMemo(() => {
    return deleteAccounts.filter((item) => item.userId.includes(keyword ?? ""));
  }, [keyword, deleteAccounts]);
  const renderCallback = (item: DeleteAccountDB) => {
    return (
      <tr key={`${item.userId}-${item.email}`}>
        <td>{item.userId}</td>
        <td>{item.email}</td>
        <td>{krwFormat(item.point)}</td>
        <td>{krwFormat(item.totalMoney)}</td>
        <td>{dateFormat(item.registerAt)}</td>
        <td>{dateFormat(item.createdAt)}</td>
      </tr>
    );
  };
  return (
    <PageContainer>
      <PageHeaderFilterWrapper>
        <SearchInput onSearch={setKeyword} placeholder="유저ID" />
      </PageHeaderFilterWrapper>
      <TableWrapper>
        <Table
          data={filterKeyword}
          renderCallback={renderCallback}
          headers={headers}
        />
      </TableWrapper>
    </PageContainer>
  );
};

export default DeleteAccountPage;

import { ChangeEvent, FC, useCallback, useState } from "react";
import { toast } from "react-toastify";
import useUpdateCategory from "../hooks/useUpdateCategory";
import Popup from "../Popup";
import { ActionPopupProps, CategoryDB } from "../types";

interface FormState {
  text: string;
  platform: string;
}

const CategoryEditPopup: FC<CategoryDB & ActionPopupProps> = ({
  text,
  id,
  platform,
  onClose,
  onSuccess,
}) => {
  const [formState, setFormState] = useState<FormState>({
    text: text,
    platform: platform ?? "",
  });

  const { mutate: updateCategoryApi } = useUpdateCategory({
    onSuccess: () => {
      toast.success("카테고리 수정 완료");
      onClose();
      if (onSuccess instanceof Function) {
        onSuccess();
      }
    },
  });

  const updateCategory = () => {
    updateCategoryApi({
      id: id,
      text: formState.text,
      platform: formState.platform,
    });
  };

  const changeFormState = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormState((prev) => ({
      ...prev,
      [name]: value,
    }));
  }, []);

  return (
    <Popup
      title={`"${text}", (${id}) 카테고리 수정`}
      onClose={onClose}
      buttonTitle="저장"
      onButtonClick={updateCategory}
    >
      <>
        <div className="field">
          <label className="label">이름</label>
          <div className="control">
            <input
              className="input"
              value={formState.text}
              name="text"
              onChange={changeFormState}
              type="text"
            />
          </div>
        </div>
        <div className="field">
          <label className="label">플랫폼</label>
          <div className="control">
            <input
              className="input"
              name="platform"
              value={formState.platform}
              onChange={changeFormState}
              type="text"
            />
          </div>
        </div>
      </>
    </Popup>
  );
};

export default CategoryEditPopup;

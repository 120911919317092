import { ChangeEvent, FC, useCallback, useState } from "react";
import styled from "styled-components";
import EditNumberInput from "../components/EditNumberInput";
import useEditPoint from "../hooks/useEditPoint";
import Popup from "../Popup";
import { toast } from "react-toastify";
import { ActionPopupProps, PointChangeDB } from "../types";
import useUserEditPoints from "../hooks/useUserEditPoints";
import Table from "../components/Table";
import { dateFormat, renderPoint } from "../utils";

const MarginContainer = styled.div`
  padding: 0 30px;
`;

const FlexContainer = styled.div`
  display: flex;

  > div:nth-child(1) {
    flex: 1;
  }
`;
const WidthContainer = styled.div`
  max-width: 650px;
  height: 500px;
`;

const headers = ["변동 포인트", "변동 내역", "변동 사유", "변동 날짜"];

const PointEditPopup: FC<ActionPopupProps & { id: string; point: number }> = ({
  id,
  onClose,
  onSuccess,
  point,
}) => {
  const [editPoint, setEditPoint] = useState<number>(0);
  const [reason, setReason] = useState<string>("");

  const { data: editPoints = [] } = useUserEditPoints(id);

  const { mutate: editPointApi } = useEditPoint({
    onSuccess: () => {
      toast.success("포인트 수정 성공");
      onClose();

      if (onSuccess instanceof Function) {
        onSuccess();
      }
    },
  });

  const changeReason = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setReason(e.target.value);
  }, []);

  const editPointFunc = () => {
    if (editPoint === 0) {
      toast.error("포인트를 입력해 주세요");
      return;
    }

    editPointApi({ point: editPoint, userId: id, reason });
  };

  const rnderCallback = (item: PointChangeDB) => {
    return (
      <tr>
        <td>{renderPoint(item.point)}</td>
        <td>
          {item.prevPoint} {"=>"} {item.nextPoint}
        </td>
        <td>{item.reason}</td>
        <td>{dateFormat(item.createdAt)}</td>
      </tr>
    );
  };

  return (
    <Popup
      title={`포인트 수정(${id})`}
      onClose={onClose}
      buttonTitle="저장"
      onButtonClick={editPointFunc}
    >
      <FlexContainer>
        <div>
          <EditNumberInput
            onChange={setEditPoint}
            title="포인트"
            value={point}
          />
          <MarginContainer>
            <div className="field">
              <label className="label">수정 사유</label>
              <div className="control">
                <input
                  className="input"
                  value={reason}
                  onChange={changeReason}
                  type="text"
                />
              </div>
            </div>
          </MarginContainer>
        </div>
        <WidthContainer>
          <Table
            data={editPoints}
            headers={headers}
            renderCallback={rnderCallback}
          />
        </WidthContainer>
      </FlexContainer>
    </Popup>
  );
};

export default PointEditPopup;

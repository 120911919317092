import { useMutation, UseMutationOption } from "react-query";
import httpClient from "../client";

const useEditPercent = (option?: UseMutationOption) => {
  return useMutation(
    async ({ userId, percent }: { userId: string; percent: number }) => {
      await httpClient.put(`/percent/${userId}`, { percent });
    },
    option
  );
};

export default useEditPercent;

import { ChangeEvent, FC, useCallback, useState } from "react";
import styled from "styled-components";
import Pagination from "../components/Pagination";
import SearchInput from "../components/SearchInput";
import Table from "../components/Table";
import useEditPoints from "../hooks/useEditPoints";
import {
  PageContainer,
  PageHeaderFilterWrapper,
  TableWrapper,
} from "../styles";
import { PointChangeDB } from "../types";
import { PointChangeType } from "../types/enum";
import {
  dateFormat,
  krwFormat,
  renderPoint,
  renderPointEditEvent,
} from "../utils";

const headers = [
  "유저 ID",
  "변동 포인트",
  "변동 내역",
  "변동 이벤트",
  "변동 사유",
  "변동 날짜",
];

const PointEditPage: FC = () => {
  const [keyword, setKeyword] = useState<string | undefined>();
  const [type, setType] = useState<PointChangeType | undefined>();
  const [page, setPage] = useState<number>(1);

  const { data = [] } = useEditPoints({ keyword, type, page });

  const changeType = useCallback((e: ChangeEvent<HTMLSelectElement>) => {
    if (e.target.value.length === 0) {
      setType(undefined);
      return;
    }
    setType(Number(e.target.value));
  }, []);

  const renderCallback = (item: PointChangeDB) => {
    return (
      <tr key={item.id}>
        <td>{item.userId}</td>
        <td>{renderPoint(item.point)}</td>
        <td>
          {krwFormat(item.prevPoint)} {" => "} {krwFormat(item.nextPoint)}
        </td>
        <td>{renderPointEditEvent(item.type)}</td>
        <td>{item.reason}</td>
        <td>{dateFormat(item.createdAt)}</td>
      </tr>
    );
  };
  return (
    <PageContainer>
      <PageHeaderFilterWrapper>
        <SearchInput placeholder="유저ID" onSearch={setKeyword} />
        <div className="select is-success">
          <select onChange={changeType} value={type ?? ""}>
            <option value="">전체</option>
            <option value={PointChangeType.PAYMENT}>입금</option>
            <option value={PointChangeType.ORDER}>주문</option>
            <option value={PointChangeType.REFUND_CANCELED}>전액환불</option>
            <option value={PointChangeType.REFUND_PARTIAL}>부분환불</option>
            <option value={PointChangeType.ADMIN_EDIT}>어드민 수정</option>
          </select>
        </div>
      </PageHeaderFilterWrapper>
      <PageHeaderFilterWrapper>
        <div />
        <Pagination onChangePage={setPage} page={page} />
      </PageHeaderFilterWrapper>
      <TableWrapper>
        <Table headers={headers} renderCallback={renderCallback} data={data} />
      </TableWrapper>
    </PageContainer>
  );
};

export default PointEditPage;

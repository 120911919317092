import { useMutation, UseMutationOption } from "react-query";
import httpClient from "../client";

const useUnRegister = (option?: UseMutationOption) => {
  return useMutation(async (id: string) => {
    await httpClient.delete(`/un-register/${id}`);
  }, option);
};

export default useUnRegister;

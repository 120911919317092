import { FC, MouseEvent, ReactElement, useCallback, useRef } from "react";
import ReactDOM from "react-dom";
import styled from "styled-components";

const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  display: flex;
`;

interface Props {
  children: ReactElement;
  onClose: () => void;
  buttonTitle?: string;
  title: string;
  width?: number;
  onButtonClick?: VoidFunction;
}

const Popup: FC<Props> = ({
  children,
  onClose,
  title,
  buttonTitle,
  onButtonClick,
  width = 1200,
}) => {
  const ref = useRef<HTMLDivElement>(null);

  const backgroundClickHandler = useCallback(
    (e: MouseEvent<HTMLDivElement>) => {
      if (e.target === ref.current) {
        onClose();
      }
    },
    [onClose]
  );

  return ReactDOM.createPortal(
    <Container className="modal" onClick={backgroundClickHandler}>
      <div className="modal-background" ref={ref}></div>
      <div className="modal-card" style={{ width }}>
        <header className="modal-card-head">
          <p className="modal-card-title">{title}</p>
        </header>
        <section className="modal-card-body">{children}</section>
        <footer className="modal-card-foot">
          {buttonTitle && (
            <button className="button is-success" onClick={onButtonClick}>
              {buttonTitle}
            </button>
          )}
          <button
            className="button is-info is-light is-outlined"
            onClick={onClose}
          >
            닫기
          </button>
        </footer>
      </div>
    </Container>,
    document.getElementById("root")!
  );
};

export default Popup;

import { FC } from "react";
import Popup from "../Popup";
import { FlexDiv } from "../styles";
import { ViewPopupProps } from "../types";
import { PaymentFeeType } from "../types/enum";

const FeePopup: FC<
  ViewPopupProps & { feeType: PaymentFeeType; feeValue: any }
> = ({ feeType, feeValue, onClose }) => {
  const render =
    feeType === PaymentFeeType.CASH ? (
      <div className="field">
        <label className="label">휴대폰번호</label>
        <div className="control">
          <input
            className="input"
            value={feeValue.phone}
            disabled
            type="text"
          />
        </div>
      </div>
    ) : (
      <>
        <FlexDiv>
          <div className="field">
            <label className="label">회사명</label>
            <div className="control">
              <input
                className="input"
                value={feeValue.company}
                type="text"
                disabled
              />
            </div>
          </div>
          <div className="field">
            <label className="label">사업자등록번호</label>
            <div className="control">
              <input
                className="input"
                value={feeValue.businessNumber}
                type="text"
                disabled
              />
            </div>
          </div>
          <div className="field">
            <label className="label">대표자</label>
            <div className="control">
              <input
                className="input"
                value={feeValue.leader}
                type="text"
                disabled
              />
            </div>
          </div>
        </FlexDiv>
        <FlexDiv>
          <div className="field">
            <label className="label">담당연락처</label>
            <div className="control">
              <input
                className="input"
                value={feeValue.phone}
                type="text"
                disabled
              />
            </div>
          </div>
          <div className="field">
            <label className="label">메일주소</label>
            <div className="control">
              <input
                className="input"
                value={feeValue.email}
                type="text"
                disabled
              />
            </div>
          </div>
        </FlexDiv>
      </>
    );

  return (
    <Popup title="현금영수증/세금계산서" onClose={onClose}>
      {render}
    </Popup>
  );
};

export default FeePopup;

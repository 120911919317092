import { FC, useCallback, useMemo, useState } from "react";
import styled from "styled-components";
import Pagination from "../components/Pagination";
import SearchInput from "../components/SearchInput";
import Table from "../components/Table";
import useOrders from "../hooks/useOrders";
import useServices from "../hooks/useServices";
import {
  PageContainer,
  PageHeaderFilterWrapper,
  TableWrapper,
} from "../styles";
import { OrderDB } from "../types";
import {
  dateFormat,
  krwFormat,
  orderStartCountFormat,
  orderStatusFormat,
  renderLink,
} from "../utils";

const OrderStatusItem = styled.div`
  padding: 7px 15px;
  background: rgb(246, 246, 246);
  border-radius: 14px;
  font-size: 12px;
  cursor: pointer;

  &.active {
    background: rgb(222, 222, 222);
  }
`;

const OrderStatusWrapper = styled.div`
  display: flex;
  gap: 0 4px;
`;

const headers = [
  "유저 아이디",
  "주문번호",
  "실행사 주문 번호",
  "지불금액",
  "주문시간",
  "입력값",
  "주문수량",
  "주문서비스",
  "남은수량",
  "주문 전 수량",
  "주문상태",
];

const OrderPage: FC = () => {
  const [keyword, setKeyword] = useState<string | undefined>(undefined);
  const [status, setStatus] = useState<string | undefined>(undefined);
  const [page, setPage] = useState<number>(1);

  const { data: services = [] } = useServices();
  const { data: orders = [] } = useOrders({
    page,
    keyword,
    status,
  });

  const statusHandler = useCallback((str: string | undefined) => {
    return () => {
      setStatus(str);
    };
  }, []);

  const renderCallback = useCallback(
    (item: OrderDB) => {
      return (
        <tr key={item.id}>
          <td>{item.userId}</td>
          <td>{item.id}</td>
          <td>{item.orderId}</td>
          <td>{krwFormat(item.point, { useWon: true })}</td>
          <td>{dateFormat(item.createdAt)}</td>
          <td>{renderLink(item.link)}</td>
          <td>{item.amount}</td>
          <td>
            {
              services.find((service) => service.id === Number(item.serviceId))
                ?.text
            }
          </td>
          <td>{item.remains}</td>
          <td>{orderStartCountFormat(item.startCount)}</td>
          <td>{orderStatusFormat(item.status)}</td>
        </tr>
      );
    },
    [services]
  );

  return (
    <PageContainer>
      <TableWrapper>
        <PageHeaderFilterWrapper>
          <OrderStatusWrapper>
            <OrderStatusItem
              className={status === undefined ? "active" : ""}
              onClick={statusHandler(undefined)}
            >
              전체
            </OrderStatusItem>
            <OrderStatusItem
              className={status === "PENDING" ? "active" : ""}
              onClick={statusHandler("PENDING")}
            >
              주문접수
            </OrderStatusItem>
            <OrderStatusItem
              className={status === "IN PROGRESS" ? "active" : ""}
              onClick={statusHandler("IN PROGRESS")}
            >
              주문준비중 또는 가동중
            </OrderStatusItem>
            <OrderStatusItem
              className={status === "COMPLETED" ? "active" : ""}
              onClick={statusHandler("COMPLETED")}
            >
              주문완료
            </OrderStatusItem>
            <OrderStatusItem
              className={status === "PROCESSING" ? "active" : ""}
              onClick={statusHandler("PROCESSING")}
            >
              주문대기
            </OrderStatusItem>
            <OrderStatusItem
              className={status === "PARTIAL" ? "active" : ""}
              onClick={statusHandler("PARTIAL")}
            >
              주문실패(작업되지 않은 만큼 환불)
            </OrderStatusItem>
            <OrderStatusItem
              className={status === "CANCELED" ? "active" : ""}
              onClick={statusHandler("CANCELED")}
            >
              주문취소 및 전액 환불
            </OrderStatusItem>
            <OrderStatusItem
              className={status === "ZOG8_CANCELED" ? "active" : ""}
              onClick={statusHandler("ZOG8_CANCELED")}
            >
              주문중 비공개 변경(환불불가)
            </OrderStatusItem>
          </OrderStatusWrapper>
        </PageHeaderFilterWrapper>
        <PageHeaderFilterWrapper>
          <SearchInput
            placeholder="주문ID, 유저ID, 주문링크"
            onSearch={setKeyword}
          />
          <Pagination onChangePage={setPage} page={page} />
        </PageHeaderFilterWrapper>
        <Table
          headers={headers}
          data={orders}
          renderCallback={renderCallback}
        />
      </TableWrapper>
    </PageContainer>
  );
};

export default OrderPage;

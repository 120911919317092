import { useQuery } from "react-query";
import httpClient from "../client";
import { OrderDB } from "../types";

const useOrders = (param: any) => {
  return useQuery<OrderDB[]>(["/orders", param], async () => {
    const res = await httpClient.get("/orders", {
      params: param,
    });
    return res.data;
  });
};

export default useOrders;

import { ChangeEvent, FC, useCallback, useState } from "react";
import { FlexDiv } from "../styles";
import { krwFormat } from "../utils";
import Section from "./Section";

interface Props {
  title: string;
  value: number;
  onChange: (value: number) => void;
}

const EditNumberInput: FC<Props> = ({ value, title, onChange }) => {
  const [editValue, setEditValue] = useState<number>(0);

  const changeEditValue = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const value = Number(e.target.value);

      setEditValue(value);
      onChange(value);
    },
    [onChange]
  );

  return (
    <Section title={title}>
      <FlexDiv>
        <div className="field">
          <label className="label">수정 전</label>
          <div className="control">
            <input
              className="input"
              disabled
              type="text"
              value={krwFormat(value)}
            />
          </div>
        </div>
        <div className="field">
          <label className="label">수정 값</label>
          <div className="control">
            <input
              className="input"
              onChange={changeEditValue}
              type="number"
              value={editValue.toString()}
            />
          </div>
        </div>
        <div className="field">
          <label className="label">수정 후</label>
          <div className="control">
            <input
              className="input"
              disabled
              type="text"
              value={krwFormat(value + editValue)}
            />
          </div>
        </div>
      </FlexDiv>
    </Section>
  );
};

export default EditNumberInput;

import { useQuery } from "react-query";
import httpClient from "../client";
import { ServiceDB } from "../types";

const useServices = () => {
  return useQuery<ServiceDB[]>("services", async () => {
    const res = await httpClient.get("/services", {});
    return res.data.reverse();
  });
};

export default useServices;

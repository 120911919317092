import { useQuery } from "react-query";
import httpClient from "../client";
import { PaymentDB } from "../types";

const usePayments = (param: any) => {
  return useQuery<PaymentDB[]>(["payments", param], async () => {
    const res = await httpClient.get("/payments", {
      params: param,
    });
    return res.data;
  });
};

export default usePayments;

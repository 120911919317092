import { useMutation, UseMutationOption } from "react-query";
import httpClient from "../client";

const useUpdateService = (option?: UseMutationOption) => {
  return useMutation(
    async (item: {
      id: number;
      serviceType: string;
      point: number;
      min: number;
      max: number;
      service: string;
      text: string;
      notice: string;
    }) => {
      await httpClient.put(`/service/${item.id}`, {
        service: item.service,
        serviceType: item.serviceType,
        point: item.point,
        min: item.min,
        max: item.max,
        text: item.text,
        notice: item.notice,
      });
    },
    option
  );
};

export default useUpdateService;

import { ChangeEvent, FC, useCallback, useState } from "react";
import { toast } from "react-toastify";
import Pagination from "../components/Pagination";
import SearchInput from "../components/SearchInput";
import Table from "../components/Table";
import confirm from "../confirm";
import useAccountLength from "../hooks/useAccountLength";
import useAccounts from "../hooks/useAccounts";
import useToggleState from "../hooks/useToggleState";
import useUnRegister from "../hooks/useUnRegister";
import useUserTotalPoint from "../hooks/useUserTotalPoint";
import AddPercentEditPopup from "../popup/AddPercentEditPopup";
import MemoEditPopup from "../popup/MemoEditPopup";
import PointEditPopup from "../popup/PointEditPopup";
import {
  PageContainer,
  PageHeaderFilterWrapper,
  SelectedTableRow,
  TableWrapper,
} from "../styles";
import { AccountDB } from "../types";
import { dateFormat, krwFormat } from "../utils";

const headers = [
  "유저 아이디",
  "비밀번호",
  "이메일",
  "포인트",
  "누적 충전 금액",
  "추가 포인트%",
  "메모",
  "가입 경로",
  "가입 시각",
  "포인트 수정",
  "추가 포인트 수정",
  "메모 수정",
  "계정 삭제",
];

const AccountPage: FC = () => {
  const [userDetail, setUserDetail] = useState<AccountDB | null>(null);
  const [page, setPage] = useState<number>(1);
  const [sort, setSort] = useState<string | undefined>(undefined);
  const [keyword, setKeyword] = useState<string | undefined>(undefined);

  const [visiblePointEditPopup, , toggleVisiblePointEditPopup] =
    useToggleState();
  const [visibleAddPercentEditPopup, , toggleVisibleAddPercentEditPopup] =
    useToggleState();
  const [visibleEditMemoPopup, , toggleVisibleEditMemoPopup] = useToggleState();

  const { data: accounts = [], refetch: refetchAccounts } = useAccounts({
    keyword,
    sort,
    page,
  });
  const { data: accountLength, refetch: refetchAccountLength } =
    useAccountLength();
  const { data: userTotalPoint, refetch: refetchUserTotalPoint } =
    useUserTotalPoint();

  const { mutate: unRegisterApi } = useUnRegister({
    onSuccess: () => {
      toast.success("계정 삭제 성공");
      refetch();
    },
  });

  const unRegister = async (userId: string) => {
    const bool = await confirm({
      title: `계정 삭제`,
      content: `${userId} 계정을 삭제하시겠습니까?`,
    });

    if (bool) {
      unRegisterApi(userId);
    }
  };

  const changeSort = useCallback((e: ChangeEvent<HTMLSelectElement>) => {
    if (e.target.value.length === 0) {
      setSort(undefined);
      return;
    }
    setSort(e.target.value);
  }, []);

  const refetch = () => {
    refetchAccounts();
    refetchAccountLength();
    refetchUserTotalPoint();
  };

  const renderCallback = (item: AccountDB) => {
    return (
      <SelectedTableRow key={item.id}>
        <td>{item.id}</td>
        <td>{item.password}</td>
        <td>{item.email}</td>
        <td>{krwFormat(item.point, { useWon: true })}</td>
        <td>{krwFormat(item.totalMoney, { useWon: true })}</td>
        <td>{item.addPercent}%</td>
        <td>{item.memo}</td>
        <td>{item.registerWhere}</td>
        <td>{dateFormat(item.createdAt)}</td>
        <td>
          <button
            onClick={() => {
              setUserDetail(item);
              toggleVisiblePointEditPopup();
            }}
            className="button  is-info is-outlined is-small"
          >
            포인트 수정
          </button>
        </td>
        <td>
          <button
            onClick={() => {
              setUserDetail(item);
              toggleVisibleAddPercentEditPopup();
            }}
            className="button is-info is-outlined is-small"
          >
            추가 포인트% 수정
          </button>
        </td>
        <td>
          <button
            onClick={() => {
              setUserDetail(item);
              toggleVisibleEditMemoPopup();
            }}
            className="button is-info is-outlined is-small"
          >
            메모 수정
          </button>
        </td>
        <td>
          <button
            onClick={() => {
              unRegister(item.id);
            }}
            className="button is-danger is-outlined is-small"
          >
            계정 삭제
          </button>
        </td>
      </SelectedTableRow>
    );
  };

  return (
    <PageContainer>
      {visiblePointEditPopup && userDetail !== null && (
        <PointEditPopup
          id={userDetail.id}
          point={userDetail.point}
          onClose={toggleVisiblePointEditPopup}
          onSuccess={refetch}
        />
      )}
      {visibleAddPercentEditPopup && userDetail !== null && (
        <AddPercentEditPopup
          id={userDetail.id}
          addPercent={userDetail.addPercent}
          onClose={toggleVisibleAddPercentEditPopup}
          onSuccess={refetchAccounts}
        />
      )}
      {visibleEditMemoPopup && userDetail !== null && (
        <MemoEditPopup
          id={userDetail.id}
          memo={userDetail.memo}
          onClose={toggleVisibleEditMemoPopup}
          onSuccess={refetchAccounts}
        />
      )}
      <PageHeaderFilterWrapper>
        <SearchInput placeholder="유저ID" onSearch={setKeyword} />
        <div className="select is-success">
          <select onChange={changeSort} value={sort ?? ""}>
            <option value="">가입순</option>
            <option value="money">포인트</option>
            <option value="totalMoney">누적충전</option>
          </select>
        </div>
      </PageHeaderFilterWrapper>
      <PageHeaderFilterWrapper>
        <div>
          <div>
            <div>총 회원수 : {krwFormat(accountLength ?? 0)}</div>
            <div>
              총 유저 포인트 :{" "}
              {krwFormat(userTotalPoint ?? 0, { useWon: true })}
            </div>
          </div>
        </div>
        <Pagination onChangePage={setPage} page={page} />
      </PageHeaderFilterWrapper>
      <TableWrapper>
        <Table
          headers={headers}
          data={accounts}
          renderCallback={renderCallback}
        />
      </TableWrapper>
    </PageContainer>
  );
};

export default AccountPage;

import { PaymentFeeType, PaymentStatus, PointChangeType } from "./types/enum";

export const dateFormat = (stamp: number) => {
  const dateObj = new Date(stamp);

  const year = dateObj.getFullYear();
  const month = (dateObj.getMonth() + 1).toString().padStart(2, "0");
  const date = dateObj.getDate().toString().padStart(2, "0");
  const hour = dateObj.getHours().toString().padStart(2, "0");
  const minute = dateObj.getMinutes().toString().padStart(2, "0");
  const second = dateObj.getSeconds().toString().padStart(2, "0");

  return `${year}-${month}-${date} ${hour}:${minute}:${second}`;
};

export const krwFormat = (num: number, option?: { useWon?: boolean }) => {
  return new Intl.NumberFormat(
    "ko-KR",
    option?.useWon
      ? {
          style: "currency",
          currency: "KRW",
        }
      : undefined
  ).format(parseInt(num.toString()));
};

export const checkLength = (
  value: string,
  { min, max }: { min: number; max: number }
) => {
  const { length } = value;

  if (length < min) return false;
  if (length > max) return false;

  return true;
};

export const getInstaLink = (value: string) => {
  try {
    const url = new URL(value);
    return url.toString();
  } catch (err) {
    return `https://instagram.com/${value}`;
  }
};

export const paymentStatusFormat = (status: PaymentStatus) => {
  switch (status) {
    case PaymentStatus.SUCCESS:
      return "수락";
    case PaymentStatus.FAIL:
      return "거절";
  }
};

export const orderStatusFormat = (status: string) => {
  switch (status) {
    case "CREATED":
      return "주문접수";
    case "PENDING":
      return "주문접수";
    case "PROCESSING":
      return "주문대기";
    case "PARTIAL":
      return "주문실패(작업되지 않은 만큼 환불)";
    case "IN PROGRESS":
      return "주문준비중 또는 가동중";
    case "CANCELED":
      return "주문취소 및 전액 환불";
    case "COMPLETED":
      return "주문완료";
    case "ZOG8_CANCELED":
      return "주문중 비공개 변경(환불불가)";
  }
};

export const renderFeeType = (feeType: PaymentFeeType) => {
  switch (feeType) {
    case PaymentFeeType.CASH:
      return "현금영수증";
    case PaymentFeeType.FEE:
      return "세금계산서";
    case PaymentFeeType.NONE:
      return "";
  }
};

export const renderPointEditEvent = (type: PointChangeType) => {
  switch (type) {
    case PointChangeType.ADMIN_EDIT: {
      return "어드민의 수정";
    }
    case PointChangeType.ORDER: {
      return `주문`;
    }
    case PointChangeType.PAYMENT: {
      return `입금`;
    }
    case PointChangeType.REFUND_PARTIAL: {
      return `부분환불`;
    }
    case PointChangeType.REFUND_CANCELED: {
      return `전액환불`;
    }
  }
};

export const orderStartCountFormat = (startCount: number) => {
  const num = Number(startCount);

  if (isNaN(num)) return "";
  if (num < 0) return "";

  return krwFormat(num);
};

export const renderLink = (link: string) => {
  const realLink = link.includes("http")
    ? link
    : `https://instagram.com/${link}`;

  return (
    <a target="_blank" href={realLink}>
      {link}
    </a>
  );
};

export const renderPoint = (point: number) => {
  const render = krwFormat(point);
  const className = point >= 0 ? "has-text-primary" : "has-text-danger";

  return (
    <span style={{ fontWeight: "bold" }} className={className}>
      {point > 0 ? "+" : ""}
      {render}
    </span>
  );
};

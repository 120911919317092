import { useQuery } from "react-query";
import httpClient from "../client";

const useTotalMoney = () => {
  return useQuery("/payment/total", async () => {
    const res = await httpClient.get(`/payment/total`);
    return res.data;
  });
};

export default useTotalMoney;

import { useQuery } from "react-query";
import httpClient from "../client";
import { PointChangeDB } from "../types";

const useUserEditPoints = (userId: string) => {
  return useQuery<PointChangeDB[]>(
    ["edit-points", userId],
    async () => {
      const res = await httpClient.get(`/point-edit/${userId}`);
      return res.data;
    },
    {
      keepPreviousData: true,
    }
  );
};

export default useUserEditPoints;

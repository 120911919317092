import { useQuery } from "react-query";
import httpClient from "../client";

const useUserTotalPoint = () => {
  return useQuery("/account/total-point", async () => {
    const res = await httpClient.get("/account/total-point");
    return res.data;
  });
};

export default useUserTotalPoint;

import { useMutation, UseMutationOption } from "react-query";
import httpClient from "../client";

const useCreateService = (option?: UseMutationOption) => {
  return useMutation(async () => {
    await httpClient.post("/service");
  }, option);
};

export default useCreateService;

import { FC, useCallback, useMemo, useState } from "react";
import SearchInput from "../components/SearchInput";
import Table from "../components/Table";
import useServices from "../hooks/useServices";
import ServicePointEditPopup from "../popup/ServicePointEditPopup";
import {
  PageContainer,
  PageHeaderFilterWrapper,
  SelectedTableRow,
  TableWrapper,
} from "../styles";
import { ServiceDB } from "../types";

const headers = ["서비스 이름"];

const ServicePointEditPage: FC = () => {
  const [serviceDetail, setServiceDetail] = useState<ServiceDB | null>(null);
  const [keyword, setKeyword] = useState<string | undefined>();

  const { data: services = [] } = useServices();

  const closeServicePointEidtPopup = useCallback(() => {
    setServiceDetail(null);
  }, []);

  const filterKeyword = useMemo(() => {
    return services.filter((item) => item.text.includes(keyword ?? ""));
  }, [keyword, services]);

  const renderCallback = (item: ServiceDB) => {
    return (
      <SelectedTableRow
        key={item.id}
        onClick={() => {
          setServiceDetail(item);
        }}
      >
        <td>{item.text}</td>
      </SelectedTableRow>
    );
  };

  return (
    <PageContainer>
      {serviceDetail !== null && (
        <ServicePointEditPopup
          {...serviceDetail}
          onClose={closeServicePointEidtPopup}
        />
      )}
      <PageHeaderFilterWrapper>
        <SearchInput placeholder="서비스 이름" onSearch={setKeyword} />
      </PageHeaderFilterWrapper>
      <TableWrapper>
        <Table
          headers={headers}
          data={filterKeyword}
          renderCallback={renderCallback}
        />
      </TableWrapper>
    </PageContainer>
  );
};

export default ServicePointEditPage;

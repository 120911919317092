import { useQuery } from "react-query";
import httpClient from "../client";

const useAccountLength = () => {
  return useQuery("/account/length", async () => {
    const res = await httpClient.get("/account/length");
    return res.data;
  });
};

export default useAccountLength;

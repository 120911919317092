import { FC, Fragment } from "react";
import { Link, useLocation } from "react-router-dom";
import styled from "styled-components";

const Container = styled.aside`
  width: 250px;
  height: 100%;
  overflow: auto;
  margin-bottom: 0 !important;
`;

const headers = [
  {
    title: "계정 관련",
    menus: [
      {
        name: "계정 관리",
        href: "/account",
      },
      {
        name: "계정 포인트 변동 내역",
        href: "/point-edit",
      },
      {
        name: "유저별 금액 설정",
        href: "/service-point-edit",
      },
      {
        name: "계정 삭제 내역",
        href: "/un-register",
      },
    ],
  },
  {
    title: "상품 관련",
    menus: [
      {
        name: "카테고리 설정",
        href: "/category",
      },
      {
        name: "상품 설정",
        href: "/service",
      },
    ],
  },
  {
    title: "주문 관련",
    menus: [
      {
        name: "주문 내역",
        href: "/order",
      },
    ],
  },
  {
    title: "입금 관련",
    menus: [
      {
        name: "입금 관리",
        href: "/payment",
      },
    ],
  },
];

const Header: FC = () => {
  const { pathname } = useLocation();
  return (
    <Container className="menu box">
      {headers.map((item, idx) => {
        return (
          <Fragment key={idx}>
            <p className="menu-label">{item.title}</p>
            {item.menus.map((item2, idx2) => {
              return (
                <ul key={idx2} className="menu-list">
                  <Link
                    className={item2.href === pathname ? "is-active" : ""}
                    to={item2.href}
                  >
                    {item2.name}
                  </Link>
                </ul>
              );
            })}
          </Fragment>
        );
      })}
    </Container>
  );
};

export default Header;

import { useMutation, UseMutationOption } from "react-query";
import httpClient from "../client";

const useEditPoint = (option?: UseMutationOption) => {
  return useMutation(
    async ({
      userId,
      point,
      reason,
    }: {
      userId: string;
      point: number;
      reason: string;
    }) => {
      await httpClient.put(`/point/${userId}`, {
        point,
        reason,
      });
    },
    option
  );
};

export default useEditPoint;

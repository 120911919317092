import { useCallback, useState } from "react";

const useToggleState = (defaultValue: boolean = false) => {
  const [bool, setBool] = useState<boolean>(defaultValue);

  const toggle = useCallback(() => {
    setBool((prev) => !prev);
  }, []);

  return [bool, setBool, toggle] as const;
};

export default useToggleState;

import { FC, useCallback, useMemo, useState } from "react";
import { toast } from "react-toastify";
import SearchInput from "../components/SearchInput";
import Table from "../components/Table";
import confirm from "../confirm";
import useCategory from "../hooks/useCategory";
import useCreateCategory from "../hooks/useCreateCategory";
import useDeleteCategory from "../hooks/useDeleteCategory";
import CategoryEditPopup from "../popup/CategoryEditPopup";
import {
  AddButton,
  PageContainer,
  PageHeaderFilterWrapper,
  SelectedTableRow,
  TableWrapper,
} from "../styles";
import { CategoryDB } from "../types";

const headers = ["ID", "카테고리 이름", "플랫폼", "삭제"];

const CategoryPage: FC = () => {
  const [keyword, setKeyword] = useState<string>("");
  const [categoryDetail, setCategoryDetail] = useState<CategoryDB | null>(null);

  const { data: categorys = [], refetch: refetchCategorys } = useCategory();

  const { mutate: deleteCategoryApi } = useDeleteCategory({
    onSuccess: () => {
      toast.success("카테고리 삭제 성공");
      refetchCategorys();
    },
  });

  const { mutate: createCategoryApi } = useCreateCategory({
    onSuccess: () => {
      toast.success("카테고리 생성 성공");
      refetchCategorys();
    },
  });

  const closeCategoryEditPopup = useCallback(() => {
    setCategoryDetail(null);
  }, []);

  const keywordHandler = useCallback((str: string | undefined) => {
    setKeyword(str ?? "");
  }, []);

  const deleteCategory = async (detail: CategoryDB) => {
    const bool = await confirm({
      title: "카테고리 삭제",
      content: `카테고리(${detail.text}, ${detail.id})가 삭제 됩니다.`,
    });

    if (bool) {
      deleteCategoryApi(detail.id);
    }
  };

  const renderCallback = (item: CategoryDB) => {
    return (
      <SelectedTableRow
        key={item.id}
        onClick={(e) => {
          setCategoryDetail(item);
        }}
      >
        <td>{item.id}</td>
        <td>{item.text}</td>
        <td>{item.platform}</td>
        <td>
          <button
            className="button is-danger is-outlined"
            onClick={(e) => {
              e.stopPropagation();
              deleteCategory(item);
            }}
          >
            카테고리 삭제
          </button>
        </td>
      </SelectedTableRow>
    );
  };

  const filterKeyword = useMemo(() => {
    return categorys.filter((item) => item.text.includes(keyword));
  }, [categorys, keyword]);

  return (
    <PageContainer>
      {categoryDetail !== null && (
        <CategoryEditPopup
          onClose={closeCategoryEditPopup}
          onSuccess={refetchCategorys}
          {...categoryDetail}
        />
      )}
      <AddButton
        className="button is-info is-outlined"
        onClick={createCategoryApi}
      >
        카테고리 추가
      </AddButton>
      <PageHeaderFilterWrapper>
        <SearchInput placeholder="카테고리 이름" onSearch={keywordHandler} />
      </PageHeaderFilterWrapper>
      <TableWrapper>
        <Table
          headers={headers}
          data={filterKeyword}
          renderCallback={renderCallback}
        />
      </TableWrapper>
    </PageContainer>
  );
};

export default CategoryPage;

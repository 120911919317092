import { useEffect } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { Flip, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import styled from "styled-components";
import Header from "./Header";
import AccountPage from "./pages/AccountPage";
import CategoryPage from "./pages/CategoryPage";
import DeleteAccountPage from "./pages/DeleteAccountPage";
import OrderPage from "./pages/OrderPage";
import PaymentPage from "./pages/PaymentPage";
import PointEditPage from "./pages/PointEditPage";
import ServicePage from "./pages/ServicePage";
import ServicePointEditPage from "./pages/ServicePointEditPage";

const Container = styled.div`
  display: flex;
  gap: 0 15px;
  align-items: stretch;
  height: 100vh;
  padding: 15px;
`;
const ContentDiv = styled.div`
  flex: 1;
  overflow: auto;
`;

function App() {
  useEffect(() => {
    const adminKey = localStorage.getItem("KEY");
    if (adminKey === null) {
      const newKey = window.prompt("ADMIN_KEY를 입력하세요.") ?? "";
      localStorage.setItem("KEY", newKey);
    }
  }, []);
  return (
    <Container>
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        transition={Flip}
        closeOnClick
        rtl={false}
        theme="dark"
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <Header />
      <ContentDiv className="box">
        <Routes>
          <Route path="/service" element={<ServicePage />} />
          <Route path="/account" element={<AccountPage />} />
          <Route path="/category" element={<CategoryPage />} />
          <Route path="/order" element={<OrderPage />} />
          <Route path="/payment" element={<PaymentPage />} />
          <Route
            path="/service-point-edit"
            element={<ServicePointEditPage />}
          />
          <Route path="/point-edit" element={<PointEditPage />} />
          <Route path="/un-register" element={<DeleteAccountPage />} />
          <Route path="/*" element={<Navigate to="/service" />} />
        </Routes>
      </ContentDiv>
    </Container>
  );
}

export default App;
